import './index.scss';

export function Loader() {
  return (
    <div className="loadingio-spinner-spinner-lksd7rs2y7">
      <div className="ldio-vd1nnrhk63">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
