import styles from './Services.module.scss';

export function Services() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Перелік послуг</h2>
      </div>
      <ul>
        <li>Допомога в оформленні статусу УБД</li>
        <li>
          <strong>Написання різних рапортів:</strong>
          <br />
          на переведення, звільнення, скерування на лікування, на отримання відпустки і т.д.
        </li>
        <li>Юридичний супровід звільнення з військової служби</li>
        <li>Оскарження висновку ВЛК</li>
        <li>Відстрочка від мобілізації</li>
        <li>Допомога в отриманні додаткової грошової винагороди за період лікування і т.д.</li>
        <li>Повний супровід у переведенні до іншої військової частини</li>
        <li>Допомога з виплатами ОГД та компенсації при загибелі військовослужбовця</li>
        <li className={styles.oddLastLi}>
          Та інші питання, які пов'язані з проходженням військової служби
        </li>
      </ul>
    </div>
  );
}
